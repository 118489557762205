<template>
  <div class="grid sm:grid-cols-2 md:flex items-start px-4 sm:px-0 justify-start sm:justify-center md:space-x-16 flex-wrap gap-5 py-6 sm:py-16">
    <div v-for="(item, index) in footerLabels" :key="`ftl-${getLocaleString(item.name)}-${index}`"
         class="flex sm:flex-col sm:space-x-0 space-x-6 items-center pt-4 sm:pt-0 sm:pl-6 justify-start sm:justify-center sm:max-w-[300px]">
      <div
          class="inline-flex items-center justify-center h-12 w-12 sm:h-16 sm:w-16 rounded-full flex-shrink-0 fill-primary opacity-60"
          :style="{'background-color': item.bgColor}">
        <NuxtImg class="sm:h-12 sm:w-12 h-6 w-6 flex items-center justify-center" :src="item.icon" />
      </div>

      <div class="text-center text-base text-primary-500 font-bold flex-grow-0">
        {{ getLocaleString(item.name) }}
      </div>

      <div class="text-center text-base text-primary-400 font-medium" v-if="item?.description && getLocaleString(item.description)">
        {{ getLocaleString(item.description) }}
      </div>
    </div>
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { footerLabels } = storeToRefs(storeConfig)
</script>